<template>
  <a-row :gutter="[32, 16]">
    <a-col :span="12">
      <div class="d-flex flex-column flex-grow-1 mb-3">
        <span class="font-weight-bold mb-1">Agents</span>
        <custom-multiselect
          v-model="selectedAgents"
          :options="agentList"
          placeholder="Select agents"
          active-label="Agents"
        />
      </div>
      <div class="d-flex flex-column flex-grow-1 mb-3">
        <span class="font-weight-bold mb-1">Currencies</span>
        <a-select
          v-model="currencyId"
          :placeholder="$t('common.currency')"
        >
          <a-select-option :value="0">
            {{ $t('pre.all') }} {{ $t('common.currencies') }}
          </a-select-option>
          <a-select-option v-for="currency in allowedCurrencies" :key="currency.id">
            <a-badge status="success" v-if="currency.is_active"/>
            <a-badge color="grey" v-else/>
            {{ currency.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="d-flex flex-column flex-grow-1 mb-3">
        <span class="font-weight-bold mb-1">Methods</span>
        <custom-multiselect
          v-model="selectedPsystems"
          :options="psystems"
          placeholder="Select methods"
          active-label="Methods"
        >
          <template v-slot:option="option">
            <span role="img">
              <img :src="option.img_path" width="16" height="16" >
            </span>
            {{ option.name }}
          </template>
        </custom-multiselect>
      </div>
    </a-col>
    <a-col :span="12">
      <div class="d-flex flex-column flex-grow-1 mb-3">
        <span class="font-weight-bold mb-1">Creation Date</span>
        <a-range-picker
          v-model="datetime"
          format="DD-MM-YYYY"
          :allowClear="false"
        />
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <span class="font-weight-bold mb-1">Requisite Status</span>
        <div class="mb-1">
          <a-checkbox
            v-model="isAllRequisiteStatus"
            @change="changeRequisiteStatusAllHandler"
            :indeterminate="isAllIndeterminateRequisiteStatus"
          >
            <span>All</span>
          </a-checkbox>
        </div>
        <div v-for="(value, key) in requisiteStatuses" :key="key">
          <div v-if="key !== 'canceled'" class="mb-1">
            <a-checkbox v-model="requisiteStatuses[key]">
              <span>{{ capitalize(key) }}</span>
            </a-checkbox>
          </div>
        </div>
      </div>
    </a-col>
    <a-col :span="24">
      <div class="d-flex flex-grow-1 justify-content-end">
        <a-button type="primary" @click="exportCSVHandler" :loading="isExportCSVLoading">Export</a-button>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomMultiselect from '../../../components/multiselect/CustomMultiselect.vue'
import { capitalize, downloadFileByLink } from '../../../utils'
import AccountsRequisitesService from '../../../services/api/accounts/requisites/accounts.requisites.service'

export default {
  name: 'exportRequisitesCSVModal',
  components: { CustomMultiselect },
  props: {
    psystems: {
      type: Array,
      default: () => [],
    },
    allowedCurrencies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedAgents: [],
      selectedPsystems: [],
      datetime: [
        this.$moment(this.$moment().utc().startOf('month'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD').endOf('day'),
      ],
      isAllRequisiteStatus: false,
      requisiteStatuses: {
        active: false,
        inactive: false,
        archived: false,
        blocked: false,
      },
      currencyId: 0,
      isExportCSVLoading: false,
    }
  },
  computed: {
    ...mapGetters(['agentList']),
    isAllIndeterminateRequisiteStatus() {
      const values = Object.values(this.requisiteStatuses)

      if (values.every(value => value === true)) {
        return false
      }

      return values.includes(true) && !values.every(value => value === false)
    },
  },
  methods: {
    capitalize,
    changeRequisiteStatusAllHandler(e) {
      const isChecked = e.target.checked

      if (isChecked) {
        Object.keys(this.requisiteStatuses).forEach(key => {
          this.requisiteStatuses[key] = true
        })
      } else {
        Object.keys(this.requisiteStatuses).forEach(key => {
          this.requisiteStatuses[key] = false
        })
      }
    },
    async exportCSVHandler() {
      try {
        this.isExportCSVLoading = true
        const payolad = this.prepareExportPayload()
        const { data } = await AccountsRequisitesService.exportCSVRequisiteAnalytics(payolad)
        downloadFileByLink(data.file_path)
        this.$emit('close')
        this.$notification.success({
          message: 'Data export was successful',
          description: '',
        })
      } catch (error) {
        console.error(error)
        this.$notification.error({
          message: 'Failed to export data',
          description: '',
        })
      } finally {
        this.isExportCSVLoading = false
      }
    },
    prepareExportPayload() {
      const statuses = Object.keys(this.requisiteStatuses).filter(key => this.requisiteStatuses[key])

      const payload = {
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
        currency_id: this.currencyId !== 0 ? this.currencyId : this.allowedCurrencies.map(c => c.id),
        agent_ids: this.selectedAgents.length ? this.selectedAgents : null,
        psystem_id: this.selectedPsystems.length ? this.selectedPsystems : null,
        statuses: statuses.length ? statuses : null,
      }

      return Object.fromEntries(Object.entries(payload).filter(([_, value]) => value !== null))
    },
  },
  watch: {
    requisiteStatuses: {
      handler(object) {
        this.isAllRequisiteStatus = Object.values(object).every(value => value === true)
      },
      deep: true,
    },
  },
}
</script>
