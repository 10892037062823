<template>
  <div class="info-requisite-order-modal">
    <div class="info-requisite-order-modal__agent font-size-18 mb-3">
      <span class="text-black-50">Agent: </span>
      <span>{{ requisite?.owner.name ?? 'Agent not found' }}</span>
    </div>
    <div class="d-flex justify-content-between">
      <div class="ml-0">
        <a-select
          v-model="selectedProjectId"
          :placeholder="$t('placeholders.selectProjects')"
          class="width-150"
          @change="fetchData"
          :loading="isLoading"
        >
          <a-select-option :value="0">
            [{{ $t('pre.all') }} {{ $t('common.projects') }}]
          </a-select-option>
          <a-select-option v-for="project in projects" :key="project.id">
            <a-badge status="success" v-if="project.is_active"/>
            <a-badge color="grey" v-else/>
            {{ project.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="ml-3">
        <a-range-picker
          v-model="datetime"
          format="YYYY-MM-DD"
          :allowClear="false"
          @change="fetchData"
        />
      </div>
    </div>
    <a-divider />
    <a-row :gutter="16">
      <a-col :span="8">
        <a-statistic v-if="!isLoading" title="Total orders" :value="totalOrders">
          <template #prefix>
            <a-icon type="wallet" />
          </template>
          <template #suffix>
            <a-tooltip title="Unique clients" placement="top">
              <a-tag class="ml-1" color="blue">
                <a-icon type="team" /> {{ uniqueClients }}
              </a-tag>
            </a-tooltip>
          </template>
        </a-statistic>
        <a-skeleton v-else :paragraph="{ rows: 1 }" />
      </a-col>
      <a-col :span="8">
        <a-statistic v-if="!isLoading" title="Cash flow" :value="totalAmount">
          <template #prefix>
            <a-icon type="sync" />
          </template>
          <template #suffix>
            <span>$</span>
            <a-tooltip title="Paid orders" placement="top">
              <a-tag class="ml-2" color="blue">
                <font-awesome-icon icon="coins" /> {{ paidOrders }}
              </a-tag>
            </a-tooltip>
            <a-tooltip title="Paid unique clients" placement="top">
              <a-tag color="blue">
                <a-icon type="team" /> {{ paidUniqueClients }}
              </a-tag>
            </a-tooltip>
          </template>
        </a-statistic>
        <a-skeleton v-else :paragraph="{ rows: 1 }" />
      </a-col>
      <a-col :span="8">
        <a-statistic v-if="!isLoading" title="Work time for the period" :value="workTime?.split(' ')[0]">
          <template #prefix>
            <a-icon type="clock-circle" />
          </template>
          <template #suffix>{{ workTime?.split(' ')[1] }}</template>
        </a-statistic>
        <a-skeleton v-else :paragraph="{ rows: 1 }" />
      </a-col>
    </a-row>
    <a-divider />
    <a-table
      :columns="tableColumns"
      :dataSource="operations"
      :row-key="record => record.id"
      :pagination="pagination"
      :loading="isLoading"
      @change="tableChangeHandler"
    >
      <template v-slot:id="id, record">
        <span>{{ id }}</span>
        <span class="d-block small text-gray-5">
            {{ record.msid.substr(0, 16) }}
          </span>
        <span v-if="record.cascade_operation" class="d-block small text-gray-5">
            {{ record.cascade_operation.tx_id.substr(0, 32) }}
          </span>
        <span class="mt-1 d-block small text-gray-5">
            <a-icon type="calendar"/> {{ $moment.parseZone(record.created_at).format('DD.MM.YY') }}
            <span class="text-gray-4">{{ $moment.parseZone(record.created_at).format('HH:mm') }}</span>
          </span>
      </template>
      <template v-slot:client="client">
        <p class="mb-0">{{ client.login }}</p>
        <p class="mb-0 d-block small text-gray-5">id: {{ client.id }}</p>
        <span v-if="client.short_name" class="mt-1 d-block small text-gray-5">
          {{ client.short_name }}
        </span>
        <div class="d-flex small text-gray-5">
          <a-tooltip title="Trust level of buy-orders" placement="bottom">
            <span class="d-block">
              <a-icon type="file-protect" /><sup>B</sup> {{ client.trust.buy_level }}%
            </span>
          </a-tooltip>
          <a-tooltip title="Trust level of sell-orders" placement="bottom">
            <span class="d-block ml-2">
              <a-icon type="file-protect" /><sup>S</sup> {{ client.trust.sell_level }}%
            </span>
          </a-tooltip>
        </div>
      </template>
      <template v-slot:amount="amount, record" >
        <div class="amount-content d-inline-flex">
          <div>
            <span class="font-weight-bolder">
              {{ amount }} {{ record.currency.abbr }}
            </span>
            <div class="small">
              <a-tooltip :title="`Client rate: ${record.rate}`" placement="bottom">
                <span class="d-block text-gray-5">{{ record.amount_usd }} $</span>
              </a-tooltip>
              <a-tooltip :title="`Agent rate: ${record.rate_a}`" placement="bottom">
                <span class="d-block text-gray-5">{{ record.amount_usd_a }} $ <agent-avatar :agent="record.account" :icon-size="12" /></span>
              </a-tooltip>
              <a-tooltip v-if="record.cascade_operation" :title="`Cascade rate: ${record.cascade_operation.rate}`" placement="bottom">
                <span class="d-block text-gray-5">{{ record.cascade_operation.amount_out }} $ <a-icon type="block" class="text-primary" /></span>
              </a-tooltip>
            </div>
          </div>
          <div v-if="record.amount_origin !== amount" class="strike text-gray-5">
            <span class="font-weight-bolder">
              {{ record.amount_origin }} {{ record.currency.abbr }}
            </span>
          </div>
        </div>
      </template>
      <template v-slot:agent_time="agent_time">
          <span v-if="agent_time" class="small">
            {{ agent_time }} <span class="ml-1">min.</span>
          </span>
      </template>
      <template v-slot:status="status, record">
        <!--          {{ status }}-->
        <a-tooltip
          :title="`(${record.chat.msg_count}) ${$t('common.messages')}${$t('pre.in')}${$t('common.chat')}`"
          placement="bottom" v-if="record.chat && record.chat.msg_count">
          <a href="javascript:" @click="openChatModal(record.chat)" class="mr-2">
            <a-icon type="wechat" class="text-primary font-size-18"/>
            <sup class="">{{ record.chat.msg_count }}</sup></a>
        </a-tooltip>
        <span class="mr-2" v-if="record.confirmed_at">
            <a-tooltip v-if="record.completed_webhook && record.destination.type === 'external'"
                       :title="$t('tooltips.projectPurchaseNotified')" placement="bottom">
              <a-icon type="api" class="text-primary font-size-18"/>
            </a-tooltip>
            <span v-else>
              <a-tooltip v-if="record.destination.type !== 'internal'"
                         :title="$t('tooltips.projectPurchaseNotNotified')" placement="bottom">
                <a-icon type="api" class="text-gray-4 font-size-18"/>
              </a-tooltip>
            </span>
          </span>
        <span v-if="record.cascade_operation" class="mr-2">
            <a-tooltip :title="`Through cascade transaction\nLast status: ${record.cascade_operation.status}`" :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <a-icon type="block" class="text-primary font-size-18"/>
            </a-tooltip>
          </span>
        <div class="order-status d-inline-block">
          <a-tooltip v-if="status === 'CREATED'" title="Awaiting agent to accept" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4"/>
              </span>
          </a-tooltip>
          <a-tooltip v-if="status === 'ACCEPTED'" title="Awaiting payment" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-5"/>
              </span>
          </a-tooltip>
          <a-tooltip
            v-if="status && ['CONFIRMED', 'ACTIVATED', 'COMPLETED'].includes(status)"
            :title="(record.card)? 'Paid \n' + record.card.brand + ': ******' + record.card.last4 : 'Paid'"
            :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-success"/>
              </span>
          </a-tooltip>
          <a-tooltip
            v-if="status === 'CANCELED'"
            :title="'Canceled' + (record.surveys && record.surveys.cancel_survey_response ? '\nReason: ' + record.surveys.cancel_survey_response.survey_option_text + '\n' + (record.surveys.cancel_survey_response.description || '') : '')"
            :overlayStyle="{ whiteSpace: 'pre-line' }" placement="bottom">
              <span class="font-size-18 mr-1">
                <font-awesome-icon icon="times-circle" class="text-danger"/>
              </span>
          </a-tooltip>
          <a-tooltip v-if="status === 'EXPIRED'" title="Expired" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-danger"/>
              </span>
          </a-tooltip>
          <a-tooltip v-if="status === 'EMPTY'" title="Empty" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-3"/>
              </span>
          </a-tooltip>
          <a-tooltip v-if="status === 'CASHED'" title="Cashed" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-6"/>
              </span>
          </a-tooltip>
          <a-tooltip v-if="status === 'REJECTED'" title="Rejected. Not confirmed!" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-warning"/>
              </span>
          </a-tooltip>
        </div>
        <a-dropdown :trigger="['click']" class="ml-2">
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            <a-icon type="bars"/>
          </a>
          <a-menu slot="overlay">
            <a-menu-item key="11"
                         v-if="userCanEdit(user, 'payments') && !(record.accepted_at === null) && !(['CONFIRMED', 'ACTIVATED', 'COMPLETED'].includes(record.status))">
              <a-popconfirm
                :title="`${$t('popups.sureToConfirmPayment')} #${record.id}?`"
                @confirm="confirmPayment(record.id)"
              >
                <a href="javascript:">
                  <a-icon type="check" class="text-success"/>
                  Confirm payment</a>
              </a-popconfirm>
            </a-menu-item>
            <a-menu-divider
              v-if="userCanView(user, 'payment.webhooks') || userCanView(user, 'payment.history')"/>
            <a-menu-item key="33"
                         v-if="userCanView(user, 'payment.webhooks') && record.destination && record.destination.type === 'external'">
              <a href="javascript:" @click="showWebhooks(record.id)">
                <a-icon type="bell"/>
                Webhooks</a>
            </a-menu-item>
            <a-menu-item key="66" v-if="userCanView(user, 'payment.history')">
              <a href="javascript:" @click="showStatusHistory(record.id)">
                <a-icon type="build"/>
                Status Timeline</a>
            </a-menu-item>
            <!--              <a-menu-divider />-->
          </a-menu>
        </a-dropdown>
        <span v-if="paymentsLoading" class="ml-2"><a-icon type="loading"/></span>
      </template>
    </a-table>
  </div>
</template>

<script>
import AccountsRequisitesOperationsService
from '../../../services/api/accounts/requisites/operations/accounts.requisites.operations.service'
import agentAvatar from '@/components/agent/AgentAvatar.vue'
import moment from 'moment-timezone'
import accessMix from '@/services/accessmix.js'
import { mapState } from 'vuex'
import PaymentsService from '../../../services/api/payments/payments.service'

const tableColumns = [
  {
    title: '№',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
    width: '15%',
  },
  {
    title: 'Client',
    dataIndex: 'client',
    key: 'client',
    width: '15%',
    scopedSlots: { customRender: 'client' },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    width: '15%',
    scopedSlots: { customRender: 'amount' },
  },
  {
    title: 'Time',
    dataIndex: 'agent_time_spent',
    key: 'agent_time_spent',
    width: '15%',
    scopedSlots: { customRender: 'agent_time' },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '15%',
    scopedSlots: { customRender: 'status' },
    align: 'right',
  },
]

export default {
  components: { agentAvatar },
  mixins: [accessMix],
  props: {
    requisite: {
      required: true,
    },
  },
  data() {
    return {
      tableColumns,
      moment,
      currentPaymentId: undefined,
      paymentsLoading: false,
      selectedProjectId: 0,
      workTime: '',
      projects: [],
      totalAmount: 0,
      paidOrders: 0,
      paidUniqueClients: 0,
      totalOrders: 0,
      uniqueClients: 0,
      datetime: [
        this.$moment(this.$moment().utc().startOf('month'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD').endOf('day'),
      ],
      operations: [],
      isLoading: true,
      pagination: {
        current: 1,
        pageSize: 3,
        total: -1,
      },
    }
  },
  computed: {
    ...mapState(['user']),
    payload() {
      return {
        results: this.pagination.pageSize,
        page: this.pagination.current,
        project: this.selectedProjectId === 0 ? null : this.selectedProjectId,
        from_date: this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss'),
        to_date: this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss'),
      }
    },
  },
  methods: {
    async tableChangeHandler(pagination) {
      this.pagination.current = pagination.current
      await this.fetchData()
    },
    async fetchData() {
      try {
        this.isLoading = true
        await this.fetchOperationStatistics()
        await this.fetchOperations()
      } catch (error) {
        console.error(error)
        this.$message.error(`Error fetching data. Message: ${error.message}`)
      } finally {
        this.isLoading = false
      }
    },
    async fetchOperations() {
      const { data, meta } = await AccountsRequisitesOperationsService.getOperations(this.requisite.id, this.payload)
      this.operations = data
      this.pagination.total = meta.total
    },
    async fetchOperationStatistics() {
      const { data } = await AccountsRequisitesOperationsService.getStatistics(this.requisite.id, this.payload)
      this.projects = data.projects
      this.workTime = data.work_time.string
      this.totalOrders = data.total_orders
      this.totalAmount = data.total_amount
      this.uniqueClients = data.unique_clients
      this.paidOrders = data.paid_orders
      this.paidUniqueClients = data.paid_unique_clients
    },
    showWebhooks(paymentId) {
      this.$emit('showWebhooks', paymentId)
    },
    showStatusHistory(paymentId) {
      this.$emit('showStatusHistory', paymentId)
    },
    openChatModal(chatObj) {
      this.$emit('showChatModal', chatObj)
    },
    async confirmPayment(paymentId) {
      try {
        await PaymentsService.confirmClientPayment(paymentId)
          .then(async () => {
            await this.fetchData()
            this.$message.success(`Payment #${paymentId} confirmed`)
          })
      } catch (error) {
        this.$message.error(`Error confirm payment. Message: ${error.message}`)
      }
    },
  },
  async mounted() {
    await this.fetchData()
  },
}
</script>
