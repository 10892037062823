<template>
<div class="accounts-content">
  <a-card class="mt-2">
    <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h5 mb-0"><font-awesome-icon :icon="['fas', 'money-check']" />
        Requisites
      </span>
    </div>
    <div class="d-flex mb-5">
      <div class="ml-0">
        <agent-dropdown v-model="accountId" @input="updateTable" />
      </div>
      <div class="ml-3">
        <a-select v-model="psystemId"
                  :placeholder="$t('placeholders.selectPaySystem')"
                  @change="updateTable"
                  class="width-200"
        >
          <a-select-option :value="0">
            [{{ $t('pre.all') }} {{ $t('common.methods') }}]
          </a-select-option>
          <a-select-option v-for="psystem in psystems" :key="psystem.id" >
                <span role="img">
                  <img :src="psystem.img_path" width="16" height="16" >
                </span>
            {{ psystem.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="ml-3">
        <a-select
          v-model="status"
          :placeholder="$t('placeholders.selectPaySystem')"
          class="width-150"
          @change="updateTable"
        >
          <a-select-option :value="0">
            [{{ $t('pre.all') }} {{ $t('common.statuses') }}]
          </a-select-option>
          <a-select-option v-for="status in statuses" :key="status.value">
            {{ status.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="ml-3">
        <a-select
          v-model="currencyId"
          :placeholder="$t('common.currency')"
          class="width-200"
          @change="updateTable"
        >
          <a-select-option :value="0">
            [{{ $t('pre.all') }} {{ $t('common.currencies') }}]
          </a-select-option>
          <a-select-option v-for="currency in currencies" :key="currency.id">
            <a-badge status="success" v-if="currency.is_active"/>
            <a-badge color="grey" v-else/>
            {{ currency.name }}
          </a-select-option>
        </a-select>
      </div>
      <div class="ml-auto">
        <a-input-search
          v-model="search"
          :placeholder="$t('placeholders.selectSearch')"
          style="width: 300px"
          allowClear
          @search="updateTable"
        >
        </a-input-search>
      </div>
    </div>
    <div class="mt-1 mb-3">
      <a-row :gutter="8" type="flex" align="bottom">
        <a-col :style="{ marginLeft: 'auto' }">
          <a-button type="primary" @click="showAddRequisiteModal">
            <a-icon type="plus" /> {{ $t('buttons.btnAdd') }} {{ $t('common.requisite') }}
          </a-button>
        </a-col>
        <a-col>
          <a-dropdown>
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="createReportCSV">
                Full Export
              </a-menu-item>
              <a-menu-item key="2" @click="openCustomReportCSVModal">
                Custom Export
              </a-menu-item>
            </a-menu>
            <a-button
              type="primary"
              icon="file-excel"
              :loading="reportCSVLoading"
              :disabled="reportCSVLoading"
            />
          </a-dropdown>
        </a-col>
        <a-col>
          <a-button @click="refreshList" :loading="table.loading" icon="reload" />
        </a-col>
      </a-row>
    </div>
    <a-table
      :columns="table.columns"
      :dataSource="table.rows"
      :row-key="record => record.id"
      :pagination="table.pagination"
      :loading="table.loading"
      @change="handleTableChange"
    >
      <template v-slot:account="account, record">
        <span class="mr-1">
            <agent-avatar :agent="account" />
        </span>
        {{ account.name }}
        <div>
          <span v-if="account.contact" style="margin-left: 2px;">
            <a-tooltip :title="`Contact via Telegram: @${account.contact.telegram}`" placement="bottom">
              <span v-if="account.contact.telegram" class="font-size-14 mr-2">
                <a :href="`https://t.me/${account.contact.telegram.replace('@','')}`" target="_blank">
                  <font-awesome-icon :icon="['fab', 'telegram']" class="text-gray-5" />
                </a>
              </span>
            </a-tooltip>
            <a-tooltip :title="`Contact via Email: ${account.contact.email}`" placement="bottom">
              <span v-if="account.contact.email" class="font-size-14 mr-2">
                <a :href="`mailto:${account.contact.email}`" target="_blank">
                  <a-icon type="mail" class="text-gray-6" />
                </a>
              </span>
            </a-tooltip>
          </span>
          <span class="font-size-10 pr-2 text-gray-5">#{{ account.id }}</span>
          <time-stamp class="mt-1" :date="record.created_at" inline />
        </div>
      </template>
      <template v-slot:psystem="psystem, record">
        <template v-if="psystem">
          <span class="p-1">
                      <a-tooltip :title="psystem.name" placement="bottom">
                        <img :src="psystem.img_path" height="24" width="24" /> {{ psystem.name }}
                      </a-tooltip>
                    </span>
          <div class="mt-1 font-size-12 text-gray-6">
            {{ $t('common.currency') }}:
            <a-tag class="ml-0 mr-1">{{ currencies.find((cur) => cur.id === record.currency.id).name }}</a-tag>
          </div>
        </template>
        <span v-else>-</span>
      </template>
      <template v-slot:requisites="address, record">
        <text-clipboard :text="address" />
        <text-clipboard :text="record.name" is-small is-grey />
      </template>
      <template v-slot:total="total, record">
        <a-tooltip title="Total shown" placement="top">
          <a @click="openInfoOrderModal(record)">
            {{ total.orders }}
          </a>
        </a-tooltip>
        <a v-if="total.unique_clients > 0" class="small text-gray-4">
          <a-tooltip title="Unique clients" placement="bottom">
            <a-icon type="team"  style="color:gray"/>
            {{ total.unique_clients }}
          </a-tooltip>
        </a>
      </template>
      <template v-slot:paid="paid, record">
        <span v-if="paid.value > 0" class="d-block">
          {{ paid.value + ' ' + record.currency.abbr }}
          <div class="small">
            <span class="d-block text-gray-5">
            {{ paid.value_usd}} $
          </span>
          </div>
        </span>
        <span v-else>-</span>
        <a v-if="paid.orders > 0" class="small text-gray-4">
          <a-tooltip title="Paid operations" placement="bottom">
            <font-awesome-icon icon="coins" style="color:gray" />
            {{ paid.orders}}
          </a-tooltip>
        </a>
        <a v-if="paid.unique_clients > 0" class="small text-gray-4">
          <a-tooltip title="Unique clients" placement="bottom">
            <a-icon type="team" style="color:gray" />
            {{ paid.unique_clients}}
          </a-tooltip>
        </a>
      </template>
      <template v-slot:status="record">
        <span v-if="record.is_draft" class="text-gray-5">
          <a-tooltip title="Archived" placement="bottom">
            <a-icon type="cloud" class="font-size-18"/>
          </a-tooltip>
        </span>
        <a-switch v-else v-model="record.is_active" :reqId="record.id" :disabled="record.is_blocked" @change="requisiteActiveState"/>
      </template>
      <template v-slot:blocked="isBlocked, record">
        <time-stamp
          v-if="isBlocked && record.blocked_at"
          :date="record.blocked_at"
        />
        <span v-else>-</span>
      </template>
      <template v-slot:operation="record">
        <div class="text-right">
          <span class="ml-3">
              <a-tooltip :title="$t('buttons.btnEdit')" placement="bottom">
                <a href="javascript:" class="font-weight-bolder font-size-18" @click="showEditRequisiteModal(record)">
                  <a-icon type="edit"/>
                </a>
              </a-tooltip>
            </span>
          <span class="ml-3">
              <a-tooltip :title="$t('buttons.btnDelete')" placement="bottom">
                <a-popconfirm
                  :title="`${$t('buttons.btnDelete')} ${$t('common.requisite')} ?`"
                  @confirm="deleteRequisite(record)"
                >
                  <a href="javascript:" class="font-weight-bolder font-size-18"><a-icon class="text-danger" type="delete" theme="filled" /></a>
                </a-popconfirm>
              </a-tooltip>
            </span>
        </div>
      </template>
    </a-table>
    <template>
      <a-modal v-model="addRequisiteModal"
               :destroyOnClose="true"
               :title="modalTitle"
               :footer="null"
               class="requisite-modal"
               width="680px"
      >
        <add-requisite-component
          :accounts="accountsData"
          :is-creating="true"
          @closeModalFunction="closeRequisiteModal"
          @submitModalFunction="requisiteModalSubmit"/>
      </a-modal>
      <a-modal
        v-model="editRequisiteModal"
        :destroyOnClose="true"
        :title="modalTitle"
        :footer="null"
        class="requisite-modal"
        width="680px"
      >
        <add-requisite-component
          :requisite-info="currentRequisite"
          :account-obj="currentOwner"
          @closeModalFunction="closeRequisiteModal"
          @submitModalFunction="requisiteModalSubmit"/>
      </a-modal>
      <a-modal
        v-if="currentRequisite"
        v-model="isInfoRequisiteOrderModalOpen"
        :after-close="closeInfoRequisiteModal"
        :title="'Order - ' + currentRequisite.address"
        :width="1000"
        :footer="null"
      >
        <info-requisite-order-modal
          @showWebhooks="showWebhooks"
          @showStatusHistory="showStatusHistory"
          @showChatModal="openChatModal"
          :requisite="currentRequisite"
        />
      </a-modal>
      <a-modal
        v-model="paymentStatusHistoryModal"
        :destroyOnClose="true"
        :title="currentPaymentId ? `Payment ${currentPaymentId} status timeline` : 'Payment status timeline'"
        :footer="null"
        class="history-modal"
        width="480px"
        height="520px"
      >
        <payment-status-history-modal
          :payment-id="currentPaymentId"
        />
      </a-modal>
      <a-modal
        v-model="paymentWebhooksModal"
        :destroyOnClose="true"
        :title="currentPaymentId ? `Payment ${currentPaymentId} webhooks` : 'Payment webhooks'"
        :footer="null"
        class="webhooks-modal"
        width="1000px"
        height="480px"
      >
        <webhooks-modal
          :payment-id="currentPaymentId"
        />
      </a-modal>
      <a-modal
        v-model="paymentChatModal"
        :destroyOnClose="true"
        title="Client-Agent Chat"
        :footer="null"
        class="chat-modal"
        width="680px"
        height="680px"
      >
        <agent-chat-modal
          :chat-obj="paymentChat"
        />
      </a-modal>
      <a-modal
        v-model="isOpenReportCSVModal"
        :destroyOnClose="true"
        title="Requisites | Export data"
        :footer="null"
        width="540px"
      >
        <export-requisites-c-s-v-modal
          :psystems="psystems"
          :allowed-currencies="allowedCurrencies"
          @close="closeCustomReportCSVModal"
        />
      </a-modal>
    </template>
  </a-card>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import { reactive } from 'vue'
import addRequisiteComponent from '@/views/accounts/requisites/addRequisiteModal.vue'
import infoRequisiteOrderModal from './infoRequisiteOrderModal.vue'
import AccountsRequisitesService from '../../../services/api/accounts/requisites/accounts.requisites.service'
import AccountsService from '../../../services/api/accounts/accounts.service'
import PaySystemsService from '../../../services/api/pay-systems/pay.systems.service'
import webhooksModal from '@/views/bills/paymentWebhooksModal.vue'
import paymentStatusHistoryModal from '@/views/bills/paymentStatusHistoryModal.vue'
import agentChatModal from '@/components/client-agent-chat/AgentChatModal.vue'
import AgentAvatar from '../../../components/agent/AgentAvatar.vue'
import AgentDropdown from '../../../components/agent/AgentDropdown.vue'
import { downloadFileByLink } from '../../../utils'
import ExportRequisitesCSVModal from './exportRequisitesCSVModal.vue'
import TextClipboard from '../../../components/table/atomic/TextClipboard.vue'
import TimeStamp from '../../../components/table/atomic/TimeStamp.vue'

export default {
  name: 'index',
  components: {
    TimeStamp,
    TextClipboard,
    ExportRequisitesCSVModal,
    AgentDropdown,
    AgentAvatar,
    agentChatModal,
    webhooksModal,
    paymentStatusHistoryModal,
    addRequisiteComponent,
    infoRequisiteOrderModal,
  },
  data() {
    return {
      accountsData: [],
      psystems: [],
      requisiteObj: null,
      editRequisiteModal: false,
      addRequisiteModal: false,
      isInfoRequisiteOrderModalOpen: false,
      paymentChat: null,
      paymentChatModal: false,
      paymentWebhooksModal: false,
      paymentStatusHistoryModal: false,
      currentPaymentId: undefined,
      currentPaymentError: undefined,
      statuses: [
        {
          value: 'active',
          name: 'Active',
        },
        {
          value: 'inactive',
          name: 'Inactive',
        },
        {
          value: 'archived',
          name: 'Archived',
        },
        {
          value: 'blocked',
          name: 'Blocked',
        },
      ],
      accountId: 0,
      psystemId: 0,
      status: 0,
      search: '',
      table: reactive({
        rows: [],
        columns: [
          {
            title: 'Account',
            dataIndex: 'owner',
            key: 'account',
            width: '15%',
            scopedSlots: { customRender: 'account' },
          },
          {
            title: 'Method',
            dataIndex: 'psystem',
            key: 'psystem',
            width: '15%',
            scopedSlots: { customRender: 'psystem' },
          },
          {
            title: 'Requisites',
            dataIndex: 'address',
            key: 'address',
            width: '15%',
            scopedSlots: { customRender: 'requisites' },
          },
          {
            title: 'Orders',
            dataIndex: 'total',
            key: 'total',
            width: '10%',
            scopedSlots: { customRender: 'total' },
          },
          {
            title: 'Paid',
            dataIndex: 'paid',
            key: 'paid',
            width: '15%',
            scopedSlots: { customRender: 'paid' },
          },
          {
            title: 'Status',
            dataIndex: '',
            width: '10%',
            scopedSlots: { customRender: 'status' },
          },
          {
            title: 'Blocked',
            dataIndex: 'is_blocked',
            width: '10%',
            scopedSlots: { customRender: 'blocked' },
          },
          {
            title: 'Action',
            dataIndex: '',
            align: 'right',
            scopedSlots: { customRender: 'operation' },
          },
        ],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 10,
          total: -1,
        },
      }),
      currencyId: 0,
      currentRequisite: null,
      currentOwner: null,
      currentRecord: null,
      reportCSVLoading: false,
      supportedCurrencies: ['RUB', 'KZT'],
      isOpenReportCSVModal: false,
    }
  },
  computed: {
    ...mapGetters(['currencies', 'projectList']),
    modalTitle() {
      return this.currentOwner ? `Edit Requisite: ${this.currentOwner.name}` : 'Add Requisite'
    },
    allowedCurrencies() {
      return this.currencies.filter(currency => this.supportedCurrencies.includes(currency.name))
    },
  },
  methods: {
    async updateTable() {
      this.table.pagination.current = 1
      await this.fetch()
    },
    async fetch() {
      try {
        this.table.loading = true
        const payload = this.prepareRequisitesData()
        const response = await AccountsRequisitesService.getAgentRequisites(payload)
        this.table.rows = response.data.data
        this.table.pagination.total = response.data.meta.total
      } catch (e) {
        console.log(e)
      } finally {
        this.table.loading = false
      }
    },
    prepareRequisitesData() {
      const payload = {
        page: this.table.pagination.current,
        results: this.table.pagination.pageSize,
        agent_id: this.accountId !== 0 ? this.accountId : null,
        psystem_id: this.psystemId !== 0 ? this.psystemId : null,
        status: this.status !== 0 ? this.status.toLowerCase() : null,
        currency_id: this.currencyId !== 0 ? this.currencyId : null,
        search: this.search.trim().length ? this.search : null,
      }

      return Object.fromEntries(Object.entries(payload).filter(([_, value]) => value !== null))
    },
    async getAccounts() {
      return AccountsService.getAccounts({ short: true, statuses: 'active' }).then(
        response => {
          this.accountsData = response.data.data
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    handleTableChange(pagination) {
      this.table.pagination.current = pagination.current
      this.fetch()
    },
    async getPsystems() {
      return PaySystemsService.getPsystems().then(
        response => {
          this.psystems = response
        },
        error => {
          return Promise.reject(error)
        },
      )
    },
    showEditRequisiteModal(record) {
      this.currentRequisite = record
      this.currentOwner = record.owner
      this.editRequisiteModal = true
    },
    async deleteRequisite(requisite) {
      return await AccountsRequisitesService.deleteRequisite(requisite.id).then((response) => {
        this.$notification.success({
          message: `${this.$t('messages.requisitesDeleted')}`,
          description: `${requisite.psystem.name}, ${requisite.address}`,
        })
        const reqIndex = this.table.rows.findIndex(obj => obj.id === requisite.id)
        this.table.rows.splice(reqIndex, 1)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: `${this.$t('messages.errorDeletingRequisite')}`,
          description: error.message,
        })
      })
    },
    async requisiteActiveState(checked, event) {
      const reqId = event.target.getAttribute('reqId')
      return AccountsRequisitesService.changeRequisiteActive(reqId, +checked).then((response) => {
        this.$notification.success({
          message: `${this.$t('messages.requisiteStatusUpdated')}`,
          description: response.address,
        })
        const reqIndex = this.table.rows.findIndex(obj => obj.id === reqId)
        this.table.rows[reqIndex] = response
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: `${this.$t('messages.errorChangingStatus')}`,
          description: error.message,
        })
      })
    },
    async closeRequisiteModal() {
      this.editRequisiteModal = false
      this.addRequisiteModal = false
      this.currentRequisite = null
      this.currentOwner = null
    },
    async requisiteModalSubmit(requisite, edit) {
      await this.updateTable()
      this.editRequisiteModal = false
      this.addRequisiteModal = false
    },
    async refreshList() {
      await this.updateTable()
    },
    async showAddRequisiteModal() {
      this.addRequisiteModal = true
    },
    closeInfoRequisiteModal() {
      this.currentRequisite = null
    },
    openInfoOrderModal(requisite) {
      this.currentRequisite = requisite
      this.isInfoRequisiteOrderModalOpen = true
    },
    openChatModal(chatObj) {
      this.paymentChat = chatObj
      this.paymentChatModal = true
    },
    showWebhooks(paymentId) {
      this.currentPaymentId = paymentId
      this.paymentWebhooksModal = true
    },
    showStatusHistory(paymentId) {
      this.currentPaymentId = paymentId
      this.paymentStatusHistoryModal = true
    },
    async createReportCSV() {
      try {
        this.reportCSVLoading = true
        const payload = this.prepareReportCSVData()
        const { data } = await AccountsRequisitesService.exportCSVRequisiteAnalytics(payload)
        downloadFileByLink(data.file_path)
      } catch (e) {} finally {
        this.reportCSVLoading = false
      }
    },
    prepareReportCSVData() {
      const payload = {
        currency_id: this.currencyId !== 0 ? [this.currencyId] : null,
        agent_ids: this.accountId !== 0 ? [this.accountId] : null,
        psystem_id: this.psystemId !== 0 ? [this.psystemId] : null,
        statuses: this.status !== 0 ? [this.status] : null,
      }

      return Object.fromEntries(Object.entries(payload).filter(([_, value]) => value !== null))
    },
    openCustomReportCSVModal() {
      this.isOpenReportCSVModal = true
    },
    closeCustomReportCSVModal() {
      this.isOpenReportCSVModal = false
    },
  },
  async mounted() {
    await this.getAccounts()
    await this.getPsystems()
    await this.fetch()
  },
}

</script>

<style scoped lang="scss">

</style>
