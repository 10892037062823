import apiClient from '../../../../axios'

export default class AccountsRequisitesOperationsService {
  static async getOperations(requisiteId, params = {}) {
    try {
      const response = await apiClient.get(`/admin/accounts/requisites/${requisiteId}/operations`, {
        params,
      })

      return response.data
    } catch (error) {
      console.error('Error fetching account requisites operations:', error)
      throw error
    }
  }

  static async getStatistics(requisiteId, params = {}) {
    try {
      const response = await apiClient.get(`/admin/accounts/requisites/${requisiteId}/statistics`, {
        params,
      })

      return response.data
    } catch (error) {
      console.error('Error fetching account requisites statistics:', error)
      throw error
    }
  }
}
